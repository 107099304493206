import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/proscom/hse-design/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Calendar, Button } from '@hse-design/react';
import { Playground, Props } from 'docz';
import { useState } from 'react';
import { ComponentLinks } from '../common';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "calendar"
    }}>{`Calendar`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`import { Calendar } from '@hse-design/react';
`}</code></pre>
    <ComponentLinks name={'Calendar'} figma={'https://www.figma.com/file/BelTU0yOKg5Dkw8r19YT1S/hse_general_library.-Version-2.0.0?node-id=9907%3A308'} storybook={'/?path=/story/calendar-calendar--playground'} vueStorybook={'/?path=/story/calendar-calendar--playground'} mdxType="ComponentLinks" />
    <h2 {...{
      "id": "описание"
    }}>{`Описание`}</h2>
    <p>{`Calendar — это компонент, который позволяет пользователям просматривать дни недели, месяца, года, выбирать одну дату или диапазон дат. Чаще всего используется как часть SingleDatePicker.`}</p>
    <p>{`Диапазон дат задаётся пропами `}<inlineCode parentName="p">{`yearFrom`}</inlineCode>{` и `}<inlineCode parentName="p">{`yearTo`}</inlineCode>{`.`}</p>
    <p>{`Определенные даты можно сделать недоступными, передав один из следующих пропов:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`disabledYears`}</inlineCode>{` – для отключения годов`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`disabledMonths`}</inlineCode>{` – для отключения месяцев`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`disabledDays`}</inlineCode>{` – для отключения дней`}</li>
    </ul>
    <p>{`Calendar контролируемый компонент: для его работы следует передать пропы `}<inlineCode parentName="p">{`value`}</inlineCode>{` и `}<inlineCode parentName="p">{`onChange`}</inlineCode></p>
    <Playground __position={1} __code={'() => {\n  const [value, setValue] = useState([new Date()])\n  return (\n    <div\n      style={{\n        backgroundColor: \'#0060CF\',\n        padding: \'30px\',\n        display: \'flex\',\n        justifyContent: \'center\',\n        alignItems: \'center\',\n        borderRadius: \'8px\',\n      }}\n    >\n      <Calendar\n        yearFrom={1920}\n        yearTo={new Date().getFullYear()}\n        value={value}\n        onChange={setValue}\n      />\n    </div>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      Calendar,
      Button,
      Playground,
      Props,
      useState,
      ComponentLinks,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [value, setValue] = useState([new Date()]);
        return <div style={{
          backgroundColor: '#0060CF',
          padding: '30px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '8px'
        }}>
        <Calendar yearFrom={1920} yearTo={new Date().getFullYear()} value={value} onChange={setValue} mdxType="Calendar" />
      </div>;
      }}
    </Playground>
    <h2 {...{
      "id": "примеры"
    }}>{`Примеры`}</h2>
    <h3 {...{
      "id": "выбор-диапазона-значений"
    }}>{`Выбор диапазона значений`}</h3>
    <p>{`Для выбора диапазона значений передайте проп передайте проп `}<inlineCode parentName="p">{`multiple`}</inlineCode>{` со значением `}<inlineCode parentName="p">{`true`}</inlineCode>{`.`}</p>
    <Playground __position={2} __code={'() => {\n  const [value, setValue] = useState([\n    new Date(),\n    new Date(Date.now() + 1000 * 60 * 60 * 24 * 7),\n  ])\n  return (\n    <div\n      style={{\n        backgroundColor: \'#0060CF\',\n        padding: \'30px\',\n        display: \'flex\',\n        justifyContent: \'center\',\n        alignItems: \'center\',\n        borderRadius: \'8px\',\n      }}\n    >\n      <Calendar\n        multiple\n        yearFrom={1920}\n        yearTo={new Date().getFullYear()}\n        value={value}\n        onChange={setValue}\n      />\n    </div>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      Calendar,
      Button,
      Playground,
      Props,
      useState,
      ComponentLinks,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [value, setValue] = useState([new Date(), new Date(Date.now() + 1000 * 60 * 60 * 24 * 7)]);
        return <div style={{
          backgroundColor: '#0060CF',
          padding: '30px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '8px'
        }}>
        <Calendar multiple yearFrom={1920} yearTo={new Date().getFullYear()} value={value} onChange={setValue} mdxType="Calendar" />
      </div>;
      }}
    </Playground>
    <h3 {...{
      "id": "выбор-недоступных-дат"
    }}>{`Выбор недоступных дат`}</h3>
    <p>{`Определенные даты можно сделать недоступными, передав один из следующих пропов:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`disabledYears`}</inlineCode>{` – для отключения годов`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`disabledMonths`}</inlineCode>{` – для отключения месяцев`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`disabledDays`}</inlineCode>{` – для отключения дней`}</li>
    </ul>
    <Playground __position={3} __code={'() => {\n  const [value, setValue] = useState([new Date()])\n  return (\n    <div\n      style={{\n        backgroundColor: \'#0060CF\',\n        padding: \'30px\',\n        display: \'flex\',\n        justifyContent: \'center\',\n        alignItems: \'center\',\n        borderRadius: \'8px\',\n      }}\n    >\n      <Calendar\n        disabledDays={[new Date(Date.now() - 86400000).toString()]}\n        disabledYears={[2020, 2021]}\n        disabledMonths={[\'12-2022\', \'11-2022\']}\n        yearFrom={1920}\n        yearTo={new Date().getFullYear()}\n        value={value}\n        onChange={setValue}\n      />\n    </div>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      Calendar,
      Button,
      Playground,
      Props,
      useState,
      ComponentLinks,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [value, setValue] = useState([new Date()]);
        return <div style={{
          backgroundColor: '#0060CF',
          padding: '30px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '8px'
        }}>
        <Calendar disabledDays={[new Date(Date.now() - 86400000).toString()]} disabledYears={[2020, 2021]} disabledMonths={['12-2022', '11-2022']} yearFrom={1920} yearTo={new Date().getFullYear()} value={value} onChange={setValue} mdxType="Calendar" />
      </div>;
      }}
    </Playground>
    <h2 {...{
      "id": "пропы"
    }}>{`Пропы`}</h2>
    <Props of={Calendar} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      